import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { iparhaiServer } from "../store/services/config/api.config";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ResetPassword = () => {
  const { resetToken } = useParams();
  const [storedResetToken, setStoredResetToken] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Store resetToken in state and localStorage
  useEffect(() => {
    const tokenFromParams = resetToken || localStorage.getItem("resetToken");
    if (tokenFromParams) {
      setStoredResetToken(tokenFromParams);
      localStorage.setItem("resetToken", tokenFromParams);
    }
  }, [resetToken]);

  // Prevent browser back navigation
//   useEffect(() => {
//     const handleBackButton = (e) => {
//       e.preventDefault();
//       return false;
//     };

//     window.history.pushState(null, "", window.location.pathname); // Ensure current URL is maintained
//     window.addEventListener("popstate", handleBackButton);

//     return () => {
//       window.removeEventListener("popstate", handleBackButton);
//     };
//   }, []);

  const handleResetPassword = async () => {
    // Ensure token exists
    if (!storedResetToken) {
      setSnackbar({
        open: true,
        message: "Invalid or missing reset token",
        severity: "error",
      });
      return;
    }

    // Check if password length is greater than 7
    if (password.length <= 7) {
      setSnackbar({
        open: true,
        message: "Password must be at least 8 characters long",
        severity: "error",
      });
      return;
    }

    // Check if password and confirmPassword match
    if (password !== confirmPassword) {
      setSnackbar({
        open: true,
        message: "Password and Confirm Password do not match",
        severity: "error",
      });
      return;
    }

    try {
      await iparhaiServer.post("/api/userAuth/forgetPassword", {
        resetToken: storedResetToken,
        password,
        confirmPassword,
      });
      setSnackbar({
        open: true,
        message: "Password reset successfully!",
        severity: "success",
      });
      setTimeout(() => {
        localStorage.removeItem('resetToken')
        window.location.href = "https://proctorparhai.com/#/login";
      }, 1000);

    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response?.data?.message || "An error occurred",
        severity: "error",
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 8,
        }}
      >
        <Typography variant="h4" gutterBottom>
          Reset Password
        </Typography>
        <TextField
          label="New Password"
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          label="Confirm Password"
          type="password"
          fullWidth
          margin="normal"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ marginTop: 2 }}
          onClick={handleResetPassword}
        >
          Reset Password
        </Button>
      </Box>

      {/* Snackbar for displaying error and success messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ResetPassword;
