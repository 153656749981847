import jwtDecode from "jwt-decode";
import { iparhaiServer } from "./config/api.config";

const login = async (email, password, recaptchaValue) => {
  return iparhaiServer
    .post("/api/userAuth/login", {
      email,
      password,
      recaptchaValue,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

const signup = async (name, role, email, password) => {
  return iparhaiServer
    .post("/api/userAuth/register", {
      name,
      role,
      email,
      password,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err.response;
    });
};
const adminsignup = (name, email, password, companyname, availableMinutes) => {
  return iparhaiServer
    .post("/api/userAuth/registerAdmin", {
      name,
      roleId: 2,
      email,
      password,
      availableMinutes,
      companyname,
      isVerified: 1,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err.response;
    });
};
const subadminsignup = (name, email, password, companyId) => {
  return iparhaiServer
    .post("/api/userAuth/Registersubadmin", {
      name,
      email,
      password,
      companyId,
      roleId: 4,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err.response;
    });
};
const getsubadmins = (companyId) => {
  return iparhaiServer
    .get(`api/userAuth/getsubadmins/${companyId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err.response;
    });
};
// const getsubadmins = async (companyId) => {
//   return iparhaiServer
//     .get(`/api/test/invitationPerTest/${testId}&${companyId}`)
//     .then((res) => {
//       return Promise.resolve(res.data);
//     })
//     .catch((err) => {
//       return Promise.reject(err);
//     });
// };
const verifyotp = async (email, otp) => {
  return iparhaiServer
    .post("/api/userAuth/verify-otp", {
      email,
      otp,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err.response;
    });
};
const getUser = (token) => {
  sessionExpiry();
  return iparhaiServer
    .get("/api/userAuth", { headers: { "x-access-token": token } })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err.response);
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem("token");
        window.location.href = "/#/login";
      }
    });
};

const resetpassword = async (userId, oldPass, newPass) => {
  return iparhaiServer
    .post("/api/userAuth/resetPassword", {
      userId,
      oldPass,
      newPass,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err.response;
    });
};

const resendOTP = async (email) => {
  try {
    const res = await iparhaiServer.post("/api/userAuth/resendOTP", { email });
    return res;
  } catch (error) {
    return error.response;
  }
};
const sessionExpiry = async () => {
  try {
    const token = localStorage.getItem("token");
    const decode = jwtDecode(token);
    let res = await iparhaiServer.get(`api/userAuth/getToken/${decode.id}`);
    if (res.data.token !== null && token !== res.data.token) {
      localStorage.setItem("multipleDevices", true);
      localStorage.removeItem("token");
      window.location.href = "/#/login";
      return false;
    }
    return true;
  } catch (error) {
    console.log(error.response);
  }
};

const registerBulkStudents = async (requestBody) => {
  return iparhaiServer
    .post("/api/userAuth/registerbulkstudents", requestBody)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err.response;
    });
};

const getmanualusers = (companyId) => {
  return iparhaiServer
    .get(`api/userAuth/getmanualusers/${companyId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err.response;
    });
};

export default {
  login,
  resendOTP,
  sessionExpiry,
  signup,
  getUser,
  adminsignup,
  verifyotp,
  resetpassword,
  subadminsignup,
  getsubadmins,
  registerBulkStudents,
  getmanualusers,
};
