import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem, Checkbox, ListItemText, InputLabel, Button, Typography } from '@mui/material';
import authService from '../../store/services/auth.service';
import testService from '../../store/services/test.service';

const SubAdminSelector = ({ open, onClose, testid }) => {
  const [subAdmins, setSubAdmins] = useState([]);
  const [selectedSubAdmins, setSelectedSubAdmins] = useState([]);
  const [errorMessage, setErrorMessage] = useState(""); 
  const [successMessage, setSuccessMessage] = useState(""); 
  const companyId = localStorage.getItem("cid");

  useEffect(() => {
    const fetchSubAdmins = async () => {
      try {
        const { data } = await authService.getsubadmins(companyId);
        setSubAdmins(data);
      } catch (err) {
        console.error("Failed to fetch sub-admins", err);
      }
    };
    fetchSubAdmins();
  }, [companyId]);

  const handleAdminChange = (event) => {
    const value = event.target.value;
    setSelectedSubAdmins(value);
  };
  console.log(subAdmins)

  const handleSave = async () => {
    try {
      // Clear any previous messages
      setErrorMessage("");
      setSuccessMessage("");
      await testService.updatepermissions(testid, selectedSubAdmins);
      // Set success message on successful update
      setSuccessMessage("Permissions updated successfully.");
      setTimeout(onClose, 2000); // Optionally close the dialog after a short delay
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message === "Some sub-admins already have permissions for this test") {
        setErrorMessage("Some sub-admins already have permissions for this test.");
      } else {
        console.error("Failed to update permissions", err);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Manage Sub-Admin Permissions</DialogTitle>
      <DialogContent>
        <InputLabel id="sub-admins-label">Select Sub-Admins to give permissions</InputLabel>
        <Select
          labelId="sub-admins-label"
          multiple
          value={selectedSubAdmins}
          onChange={handleAdminChange}
          renderValue={(selected) => selected
            .map(id => subAdmins.find(admin => admin.id === id)?.name || '')
            .join(', ')}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 224,
                width: 250,
              },
            },
          }}
        >
          {subAdmins.map((admin) => (
            <MenuItem key={admin.id} value={admin.id}>
              <Checkbox checked={selectedSubAdmins.indexOf(admin.id) > -1} />
              <ListItemText primary={admin.name} />
            </MenuItem>
          ))}
        </Select>
        {/* Show error message */}
        {errorMessage && <Typography color="error" style={{ marginTop: 16 }}>{errorMessage}</Typography>}
        {/* Show success message */}
        {successMessage && <Typography color="success" style={{ marginTop: 16 }}>{successMessage}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubAdminSelector;
