import { Box, Paper, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import AssessCard from "../Assessment/AssessCard";
import ForwardRoundedIcon from "@mui/icons-material/ForwardRounded";
import * as React from "react";
import { useState } from "react";
import { iparhaiServer } from "../../store/services/config/api.config";
import TestTable from "./TestTable";
import ViewReportModal from "./ViewReportModal";
import {
  handleGenerateReport,
  formatDate,
  viewReport,
  handleDownloadDocx,
} from "../../utils/sceduleCard.function";
import { useSelector } from "react-redux";
import AssignmentModal from "./AssignmentModal";

function ScheduleCard({ test }) {

  const [ShowScore, setShowScore] = useState(false);
  const [pdfDataUrl, setPdfDataUrl] = useState(null);
  const [openAssignmentModal, setOpenAssignmentModal] = useState(false)
  const [userDetails, setUserDetails] = useState({ name: "", email: "", username: "" });
  const [viewReportModal, setViewReportModal] = useState(false);
  const [assignmentQuestions , setAssignmentQuestions] = useState([])
  const [viewQuestionLoading, setViewQuestionLoading] = useState(false);
  const [assignmentLoading, setAssignmentLoading] = useState(false);
  const [questionData, setQuestionData] = useState([]);

  const toggleScoreButton = () => {
    setShowScore(!ShowScore);
  };

  const getQuestions = async (row) => {
    try {
      const userId = typeof row === "object" ? row.id : row;
      const user = test.scores.find((score) => score.userId === userId);

      setUserDetails({ name: row.name, email: user.user.email, id: userId, username: user.user.username });
      setViewReportModal(!viewReportModal);
      setViewQuestionLoading(true);
      setQuestionData([]);
      const params = {
        testId: test.test.id,
        userId: row.id,
      };
      const res = await iparhaiServer.get(`/api/question/aws/getUserQuestion`, {
        params,
      });
      setViewQuestionLoading(false);
      const sortedData = res.data.sort((a, b) => a.sequence - b.sequence);
      setQuestionData(sortedData);
    } catch (error) {
      setViewQuestionLoading(false);
      setQuestionData([]);
    }
  };

  const getAssignment = async (row) => {
    try {
      setAssignmentLoading(true)
      const userId = typeof row === "object" ? row.id : row;
      const user = test.scores.find((score) => score.userId === userId);
      setOpenAssignmentModal(true)

      const params = {
        testId: test.test.id,
        userId: row.id,
      };

      const res = await iparhaiServer.get(`/api/question/aws/getUserAssignment`, {
        params,
      });
      setAssignmentQuestions(res.data)
      setAssignmentLoading(false)
    } catch (error) {
      console.log(error.response)
      setAssignmentLoading(false)
    }
  }

  function createData(
    id,
    name,
    username,
    score,
    marks,
    tab_switch,
    centerGaze,
    notCenterGaze,
    similarityscore
  ) {
    return {
      id,
      name,
      username,
      score,
      marks,
      tab_switch,
      centerGaze,
      notCenterGaze,
      similarityscore,
    };
  }

  const rows =
    test.scores.length > 0 &&
    test.scores.map((score) => {
      return createData(
        score.userId,
        score.user.name,
        score.user.username,
        score.score,
        score.marks,
        score.tab_switch,
        score.centerGaze,
        score.notCenterGaze,
        score.similarityscore
      );
    });

  const getColumns = (test) => {
    let baseColumns = [
      { field: "name", headerName: "Name", flex: 1, align: "left" },
      { field: "username", headerName: "Username", flex: 1, align: "left" },

    ];

    // Add columns based on test type and enableAiVigilance
    if (test.test.QuestionType === "MCQ-based") {
      baseColumns.splice(2, 0, {
        field: "tab_switch",
        headerName: "Tab Switch",
        width: 80,
        flex: 1,
        align: "left",
      });

      baseColumns.splice(3, 0, {
        field: "score",
        headerName: "Correct",
        align: "left",
        valueGetter: (params) =>
          `${params.row.score}${params.row.score !== "-" ? "" : ""}`,
      });
      baseColumns.splice(4, 0, {
        field: "marks",
        headerName: "Marks",
        align: "left",
        valueGetter: (params) =>
          `${params.row.marks}${params.row.marks !== "-" ? "" : ""}`,
      });
      baseColumns.splice(5, 0, {
        field: "genReport",
        headerName: "",
        width: 125,
        align: "left",
        renderCell: (params) => (
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: 140,
              fontSize: "0.75rem",
              padding: "0.45rem",
              textTransform: "none",
            }}
            onClick={() => handleGenerateReport(params.row, test)}
          >
            Generate Report
          </Button>
        ),
      },);
      baseColumns.splice(6, 0, {
        field: "viewReport",
        headerName: "",
        width: 100,
        align: "left",
        renderCell: (params) => (
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: 140,
              fontSize: "0.75rem",
              padding: "0.45rem",
              textTransform: "none",
            }}
            onClick={() => {
              if (test.test.QuestionType === "MCQ-based") {
                viewReport(params.row, test, setPdfDataUrl, setViewReportModal);
              } else {
                getQuestions(params.row);
              }
            }}
          >
            View Report
          </Button>
        ),
      });
      if (test.test.enableAiVigilance) {
        baseColumns.splice(5, 0, {
          field: "centerGaze",
          headerName: "Center",
          width: 100,
          flex: 1,
          align: "left",
          valueGetter: (params) =>
            `${params.row.centerGaze == null ? "0" : params.row.centerGaze}`,
        });

        baseColumns.splice(6, 0, {
          field: "notCenterGaze",
          headerName: "Not Center",
          width: 100,
          flex: 1,
          align: "left",
          valueGetter: (params) =>
            `${params.row.notCenterGaze == null ? "0" : params.row.notCenterGaze}`,
        });

        baseColumns.splice(7, 0, {
          field: "similarityscore",
          headerName: "similarityscore",
          width: 100,
          flex: 1,
          align: "left",
        });
      }
    }

    if (test.test.QuestionType === "Text-based") {
      baseColumns.splice(1, 0, {
        field: "tab_switch",
        headerName: "Tab Switch",
        width: 80,
        flex: 1,
        align: "left",
      });
      baseColumns.splice(5, 0, {
        field: "genReport",
        headerName: "",
        width: 125,
        align: "left",
        renderCell: (params) => (
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: 140,
              fontSize: "0.75rem",
              padding: "0.45rem",
              textTransform: "none",
            }}
            onClick={() => handleGenerateReport(params.row, test)}
          >
            Generate Report
          </Button>
        ),
      },);
      baseColumns.splice(6, 0, {
        field: "viewReport",
        headerName: "",
        width: 100,
        align: "left",
        renderCell: (params) => (
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: 140,
              fontSize: "0.75rem",
              padding: "0.45rem",
              textTransform: "none",
            }}
            onClick={() => {
              if (test.test.QuestionType === "MCQ-based") {
                viewReport(params.row, test, setPdfDataUrl, setViewReportModal);
              } else {
                getQuestions(params.row);
              }
            }}
          >
            View Report
          </Button>
        ),
      });

      if (test.test.enableAiVigilance) {
        baseColumns.splice(2, 0, {
          field: "centerGaze",
          headerName: "Center",
          width: 100,
          flex: 1,
          align: "left",
        });

        baseColumns.splice(3, 0, {
          field: "notCenterGaze",
          headerName: "Not Center",
          width: 100,
          flex: 1,
          align: "left",
        });

        baseColumns.splice(4, 0, {
          field: "similarityscore",
          headerName: "similarityscore",
          width: 100,
          flex: 1,
          align: "left",
        });
      }
    }
    if (test.test.QuestionType === "Assignment-based") {
      baseColumns.splice(3, 0, {
        field: "genReport",
        headerName: "File",
        width: 125,
        align: "left",
        renderCell: (params) => (
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: 140,
              fontSize: "0.75rem",
              padding: "0.45rem",
              textTransform: "none",
            }}
            onClick={() => getAssignment(params.row)}
          >
            Assignment File
          </Button>
        ),
      },);

    }

    return baseColumns;
  };

  const columns = getColumns(test);

  return (
    <>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "1.5rem",
        }}
      >
        <Box
          sx={{
            bgcolor: "#004E8F",
            color: "white",
            alignSelf: "flex-end",
            padding: "0.5rem",
            borderRadius: "5px",
          }}
        >
          <Typography
            sx={{
              fontSize: "0.8rem",
            }}
          >
            Scheduled for
          </Typography>
          <Typography
            sx={{
              fontSize: "0.8rem",
            }}
            component={"span"}
            fontWeight={800}
          >
            {formatDate(test.test.startdate, test.test.timezone)} -{" "}
          </Typography>
          <Typography
            sx={{
              fontSize: "0.8rem",
            }}
            component={"span"}
            fontWeight={800}
          >
            {formatDate(test.test.expiredate, test.test.timezone)}
          </Typography>
        </Box>

        <Box>
          {ShowScore && (
            <Button
              className="m-40 bg-black"
              variant="contained"
              color="primary"
              sx={{
                fontSize: "0.6rem",
                padding: "0.25rem",
                textTransform: "none",
                margin: "15px",
              }}
              onClick={() => setShowScore(!ShowScore)}
            >
              <ForwardRoundedIcon className="rotate-180" />
            </Button>
          )}

          {!ShowScore && (
            <AssessCard
              test={test.test}
              toggle={toggleScoreButton}
              ShowScore={ShowScore}
            />
          )}

          {ShowScore && rows && <TestTable rows={rows} columns={columns} />}

          {/* view report modal */}
          <ViewReportModal
            open={viewReportModal}
            onClose={() => {
              setPdfDataUrl(null);
              setViewReportModal(false);
            }}
            pdfDataUrl={pdfDataUrl}
            questionData={questionData}
            test={test}
            setPdfDataUrl={setPdfDataUrl}
            setViewReportModal={setViewReportModal}
            viewQuestionLoading={viewQuestionLoading}
            handleDownloadDocx={() =>
              handleDownloadDocx(userDetails, questionData)
            }
            viewReport={viewReport}
            userDetails={userDetails}
            gradelevel={test.test.gradelevel}
            subject={test.test.subject}
          />
          <AssignmentModal openAssignmentModal={openAssignmentModal} assignmentLoading={assignmentLoading} setOpenAssignmentModal={setOpenAssignmentModal} questionData={assignmentQuestions} />
          {ShowScore && !rows && (
            <Paper
              sx={{
                height: "fit-content",
                p: 1,
              }}
            >
              <Typography
                sx={{
                  alignSelf: "center",
                  fontWeight: 800,
                  fontSize: "1.5rem",
                  color: "black",
                }}
              >
                Test Not Attempted Yet
              </Typography>{" "}
            </Paper>
          )}
        </Box>
      </Paper>
    </>
  );
}

export default ScheduleCard;
