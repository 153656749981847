import {
  faAt,
  faEye,
  faEyeSlash,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Backdrop,
  Box,
  Button,
  Container,
  Divider,
  Fade,
  Grid,
  InputBase,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";
import auth from "../../store/actions/auth";
import user from "../../store/actions/user";
import authService from "../../store/services/auth.service";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import SideBar from "../../components/Navigation/SideBar";
import Alert from "@mui/material/Alert";



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#B0C8DC",
  border: "4px solid #004E8F",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

function Password() {
  const token = localStorage.getItem('token')
  const dispatch = useDispatch();

  const [inputs, setInputs] = useState({
    oldPassword: "",
    newPassword: "",
    re_password: "",
  });

  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
  });

  const [visibleOldPass, setVisibleOldPass] = useState(false);
  const [openModal , setOpenModal] = useState(false)
  const [modalContent , setModalContent] = useState('')
  const [visibleRePass, setVisibleRePass] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [rePassError, setrePassError] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  function handleOnChange(e) {
    const value = e.target.value.replace(/\s+/g, ""); // Remove spaces
    setInputs((prevState) => ({
      ...prevState,
      [e.target.id]: value,
    }));
  }
  

  async function handleSubmit(e) {
    e.preventDefault();
    setPasswordError(false);
    setrePassError(false);
    setError({
      isError: false,
      errorMessage: "",
    });
  
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/; // Regex to check for at least one letter, one number, and one special character
  
    if (
      inputs.newPassword.length >= 8 &&
      inputs.newPassword === inputs.re_password &&
      inputs.oldPassword !== inputs.newPassword &&
      passwordRegex.test(inputs.newPassword) // Check for alphabets, numbers, and special characters
    ) {
      try {
        let id = jwt_decode(token).id;
        setLoading(true);
        const res = await authService.resetpassword(id, inputs.oldPassword, inputs.newPassword);
        setLoading(false);
        setOpenModal(true);
        setModalContent(res.data);
        setInputs({
          oldPassword: "",
          newPassword: "",
          re_password: "",
        });
        setTimeout(() => {
          setModalContent('');
          setOpenModal(false);
        }, 1500);
      } catch (error) {
        setLoading(false);
        setError(
          error.data
            ? {
                isError: true,
                errorMessage: error.data,
              }
            : {
                isError: true,
                errorMessage: error.message,
              }
        );
      }
    } else {
      setPasswordError(!(inputs.newPassword.length >= 8));
      setrePassError(!(inputs.newPassword === inputs.re_password));
      if (inputs.oldPassword === inputs.newPassword) {
        setError({
          isError: true,
          errorMessage: "New password must be different from the old password.",
        });
      } else if (!passwordRegex.test(inputs.newPassword)) {
        setError({
          isError: true,
          errorMessage: "Password must contain at least one letter, one number, and one special character.",
        });
      }
    }
  }
  
  

  return (
    <>
      <Box
        height="100%"
        sx={{
          position: "relative",
          bgcolor: "#F8F8F8",
        }}
      >
         <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        // onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="transition-modal-title" variant="h6" component="h2"></Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {modalContent}
            </Typography>
          </Box>
        </Fade>
      </Modal>

        <Grid
          container
          spacing={0}
          sx={{
            display: "flex",
          }}
        >
          <Grid item xs={0} sm={3}>
            <SideBar />
          </Grid>
          <Grid
            item
            xs={12}
            sm={9}
            sx={{
              alignSelf: "center",
              margin: "auto",
            }}
          >
            <>
              <Container
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  width="60%"
                  my={2}
                  p={5}
                  bgcolor={"#B0C8DC"}
                  borderRadius={2}
                >
                  <Box sx={{ mt: 5, position: "relative" }}>
                    {error.isError && (
                      <Alert severity="error" sx={{ mb: 2 }}>
                        {error.errorMessage}
                      </Alert>
                    )}

                    <Typography
                      fontWeight={800}
                      sx={{ mb: 1, mt: 2, userSelect: "none" }}
                    >
                      old Password
                    </Typography>
                    <Paper
                      component="form"
                      sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <InputBase
                        id="oldPassword"
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Enter Your Old Password"
                        type={visibleOldPass ? "text" : "password"}
                        value={inputs.oldPassword}
                        autoComplete={false}
                        onChange={handleOnChange}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSubmit();
                          }
                        }}
                      />
                      <FontAwesomeIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setVisibleOldPass((prevState) => !prevState);
                        }}
                        color="#004E8F"
                        icon={visibleOldPass ? faEye : faEyeSlash}
                      />
                    </Paper>
                    <Typography
                      fontWeight={800}
                      sx={{ mb: 1, mt: 2, userSelect: "none" }}
                    >
                      New Password
                    </Typography>
                    <Paper
                      component="form"
                      sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <InputBase
                        id="newPassword"
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Enter Your New Password"
                        type={visible ? "text" : "password"}
                        value={inputs.newPassword}
                        autoComplete={false}
                        onChange={handleOnChange}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSubmit();
                          }
                        }}
                      />
                      <FontAwesomeIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setVisible((prevState) => !prevState);
                        }}
                        color="#004E8F"
                        icon={visible ? faEye : faEyeSlash}
                      />
                    </Paper>
                    {passwordError ? (
                      <Typography variant="body2" color="red" ml={1}>
                        Password must be at least 8 characters
                      </Typography>
                    ) : (
                      <Typography variant="body2" color="red" ml={1}>
                        {rePassError && "Password does not match"}
                      </Typography>
                    )}

                    <Typography
                      fontWeight={800}
                      sx={{ mb: 1, mt: 2, userSelect: "none" }}
                    >
                      Re-Enter Password
                    </Typography>
                    <Paper
                      component="form"
                      sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <InputBase
                        id="re_password"
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Re-Enter Your Password"
                        type={visibleRePass ? "text" : "password"}
                        value={inputs.re_password}
                        autoComplete={false}
                        onChange={handleOnChange}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSubmit();
                          }
                        }}
                      />
                      <FontAwesomeIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setVisibleRePass((prevState) => !prevState);
                        }}
                        color="#004E8F"
                        icon={visibleRePass ? faEye : faEyeSlash}
                      />
                    </Paper>
                    {passwordError ? (
                      <Typography variant="body2" color="red" ml={1}>
                        Password must be at least 8 characters
                      </Typography>
                    ) : (
                      <Typography variant="body2" color="red" ml={1}>
                        {rePassError && "Password does not match"}
                      </Typography>
                    )}

                    <Button
                      variant="contained"
                      sx={{ width: "100%", mt: 8, ml: 0.5, mb: 2 }}
                      color="primary"
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      {loading ? "Waiting..." : "Reset Password"}
                      {loading && (
                        <FontAwesomeIcon
                          icon={faSpinner}
                          spin
                          style={{ marginLeft: "8px" }}
                        />
                      )}
                    </Button>
                  </Box>
                </Box>
              </Container>
            </>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Password;