import React, { useEffect, useState } from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  Modal,
  Box,
  Alert,
  Tooltip,
} from "@mui/material";
import imageCompression from "browser-image-compression";
import CheckIcon from "@mui/icons-material/Check";
import CryptoJS from "crypto-js";
import { iparhaiServer } from "../../store/services/config/api.config";
import { useSelector } from "react-redux";
import { debounce } from "lodash";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const secretKey = "your-secret-key-that-should-never-be-shared-with_anyone"; // Replace with a more secure key in a real application

const encryptData = (data) => {
  return CryptoJS.AES.encrypt(data, secretKey).toString();
};

const decryptData = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};

// Function to count words
const countWords = (text) => {
  return text.trim().split(/\s+/).filter(Boolean).length;
};

const AnswerInput = ({
  Mode,
  textAnswer,
  setTextAnswer,
  selectedOption,
  setSelectedOption,
  questions,
  questionNumber,
  handleAnswer,
  assignmentFile,
  setAssignmentFile,
  setPdfPreviewUrl
}) => {
  const userId = localStorage.getItem("userId");
  const reloadPage = useSelector((state) => state.tests.reload);
  const [open, setOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const handleSave = async () => {
    try {
      if (!textAnswer) {
        return;
      }
      await iparhaiServer.post(`/api/test/saveAnswer`, {
        questionId: questions[questionNumber]._id,
        userId,
        answer: textAnswer,
      });
      setShowAlert(true);
    } catch (error) {
      console.log(error.response);
    } // Show the alert
  };

  const debouncedHandleSave = debounce(handleSave, 3000);

  useEffect(() => {
    if (reloadPage) {
      handleSave();
    }
  }, [reloadPage]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await iparhaiServer.get(
          `/api/test/savedAnswer/${questions[questionNumber]._id}&${userId}`
        );
        setTextAnswer(res.data);
      } catch (error) {
        console.error("Error fetching answer:", error);
      }
    };

    if (Mode === "Text-based") {
      fetchData();
    }
  }, [Mode, setTextAnswer, questions, questionNumber, userId]);

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 3000); // Hide alert after 3 seconds

      return () => clearTimeout(timer); // Clean up the timer
    }
  }, [showAlert]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "s") {
        event.preventDefault(); // Prevent the default browser save action
        handleSave();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleSave]);

  // Auto-save functionality after every 5 words
  useEffect(() => {
    const wordCount = countWords(textAnswer);
    if (wordCount > 0 && wordCount % 5 === 0) {
      debouncedHandleSave();
    }
  }, [textAnswer]);

  const handleTextFieldChange = (e) => {
    setTextAnswer(e.target.value);
  };

  const handleOpen = (url) => {
    setPreviewImage(url);
    setOpen(true);
  };

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     setUploadedFileName(file.name);
  //     console.log(file);
  //     setAssignmentFile(file);
  //   }
  // };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedFileName(file.name);
        setAssignmentFile(file);
        setPdfPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      {Mode === "Text-based" ? (
        <div className="w-full mt-10">
          <div className="flex items-center justify-end gap-4 mb-2">
            <div className="mr-auto flex items-center">
              <div className="font-semibold">Word Counter: </div>
              <div className="h-10 w-10 rounded-full grid place-items-center heo wthe tjeh center the dic ther dpw dh  df bg-[#004e8f] ml-2 text-white ">
                <div>{countWords(textAnswer)}</div>
              </div>
            </div>
            {showAlert && (
              <Alert
                className="fixed top-2 right-2 z-[1000]"
                icon={<CheckIcon fontSize="inherit" />}
                severity="success"
              >
                Successfully Saved!
              </Alert>
            )}

            <Tooltip title="Ctrl + S" placement="top">
              <Button variant="contained" onClick={handleSave}>
                Save Answer
              </Button>
            </Tooltip>
          </div>
          <Box
            sx={{
              overflowWrap: "break-word",
              wordWrap: "break-word",
              hyphens: "auto",
            }}
          >
            <TextField
              label="Answer"
              variant="outlined"
              multiline
              autoCorrect={false}
              rows={questions[questionNumber].type === "Short Answer" ? 4 : 14}
              fullWidth
              value={textAnswer}
              onChange={handleTextFieldChange}
            />
          </Box>
        </div>
      ) : Mode === "Assignment-based" ? (
        <>
          <div className="mt-10">
            <input
              accept="image/*,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              style={{ display: "none" }}
              id="pdfFileInput"
              type="file"
              onClick={(event) => (event.target.value = null)} // Reset file input
              onChange={handleFileChange}
            />
            <label htmlFor="pdfFileInput">
              <Button
                variant="contained"
                component="span"
                color={uploadedFileName ? "error" : "primary"}
              >
                {uploadedFileName
                  ? `Change File (${uploadedFileName})`
                  : "Upload File"}
              </Button>
            </label>
          </div>
        </>
      ) : (
        <FormControl className="self-start w-full">
          <RadioGroup
            className="mt-10"
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={selectedOption}
            onChange={handleAnswer}
          >
            <div className="flex gap-10 items-center justify-between w-full">
              <FormControlLabel
                value={questions[questionNumber].option1}
                control={<Radio />}
                label={questions[questionNumber].option1}
              />
              {questions[questionNumber].optionImage1 && (
                <>
                  <div className="flex gap-2 items-center">
                    <Button
                      onClick={() =>
                        handleOpen(questions[questionNumber].optionImage1)
                      }
                      variant="outlined"
                    >
                      IMAGE
                    </Button>
                    <span className="text-sm text-slate-400">
                      Click image to open
                    </span>
                  </div>
                </>
              )}
            </div>
            <div className="flex gap-10 rounded-xl items-center justify-between w-full">
              <FormControlLabel
                value={questions[questionNumber].option2}
                control={<Radio />}
                label={questions[questionNumber].option2}
              />
              {questions[questionNumber].optionImage2 && (
                <>
                  <div className="flex gap-2 items-center">
                    <Button
                      onClick={() =>
                        handleOpen(questions[questionNumber].optionImage2)
                      }
                      variant="outlined"
                    >
                      IMAGE
                    </Button>
                    <span className="text-sm text-slate-400">
                      Click image to open
                    </span>
                  </div>
                </>
              )}
            </div>
            <div className="flex gap-10 items-center justify-between w-full">
              <FormControlLabel
                value={questions[questionNumber].option3}
                control={<Radio />}
                label={questions[questionNumber].option3}
              />
              {questions[questionNumber].optionImage3 && (
                <>
                  <div className="flex gap-2 items-center">
                    <Button
                      onClick={() =>
                        handleOpen(questions[questionNumber].optionImage3)
                      }
                      variant="outlined"
                    >
                      IMAGE
                    </Button>
                    <span className="text-sm text-slate-400">
                      Click image to open
                    </span>
                  </div>
                </>
              )}
            </div>
            <div className="flex gap-10 items-center justify-between w-full">
              <FormControlLabel
                value={questions[questionNumber].option4}
                control={<Radio />}
                label={questions[questionNumber].option4}
              />
              {questions[questionNumber].optionImage4 && (
                <>
                  <div className="flex gap-2 items-center">
                    <Button
                      onClick={() =>
                        handleOpen(questions[questionNumber].optionImage4)
                      }
                      variant="outlined"
                    >
                      IMAGE
                    </Button>
                    <span className="text-sm text-slate-400">
                      Click image to open
                    </span>
                  </div>
                </>
              )}
            </div>
          </RadioGroup>
        </FormControl>
      )}
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <div className="w-[800px] flex justify-center">
            <img
              src={previewImage}
              alt="Preview"
              style={{ maxHeight: "600px" }}
            />
          </div>
          <Button
            onClick={handleClose}
            variant="contained"
            style={{ marginTop: "10px" }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default AnswerInput;
