import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { useState } from "react";
import { iparhaiServer } from "../../store/services/config/api.config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const QuestionAccordion = ({ no, questionID, question, answer, totalMarks, gradelevel, subject, autoGradingMarks }) => {
  const [autoGradeMarks, setAutoGradeMarks] = useState(autoGradingMarks);
  const [loading, setLoading] = useState(false);

  const handleAutoGrading = async (q, a, m, g) => {
    try {
      setLoading(true);
      const res = await iparhaiServer.post("/api/question/gradeanswer", {
        question: q,
        questionID,   
        answer: a,
        subject,
        total_marks: m,
        grade: g,
        is_reason: true,
        conditions: null
      });
      setLoading(false);
      setAutoGradeMarks(res.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };



  return (
    <>
      <Accordion key={no}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <div className="w-full flex justify-between">
            <Typography>
              <span className="font-semibold">QUESTION No. {++no}:</span>{" "}
              {question}
            </Typography>
            <p className="mx-4 bg-[#b0c8dc] p-1 rounded-lg "><span className="font-medium">Total Marks:</span> <span className="text-[#004e8f] font-semibold">{totalMarks}</span></p>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex justify-between">

          <Typography sx={{
            overflowWrap: "break-word",
            wordWrap: "break-word",
            hyphens: "auto",
          }} className="max-w-[80%]">
            <span className="font-semibold">ANSWER: </span>
            {answer}
          </Typography>

          <div className="flex flex-col items-center h-fit bg-slate-100 rounded-lg px-10 py-2  w-[20%]">
            <div className=" my-3">
              <Button
                variant="outlined"
                sx={{
                  width: '142px',
                  height: '35px',
                  p: 0
                }}
                onClick={() => handleAutoGrading(question, answer, totalMarks, gradelevel)}
              >
                {loading && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                  // style={{ marginLeft: "8px" }}
                  />
                )}
                {!loading && 'Auto Grading'}
              </Button>
            </div>
            <div className="">
              <code className="font-semibold">Marks:</code>
              <span className=" px-4 py-2 rounded-lg">
                {autoGradeMarks}
              </span>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default QuestionAccordion;
