import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, Fade, Typography } from '@mui/material';
import authService from '../../store/services/auth.service'; // Make sure this path is correct
import { iparhaiServer } from '../../store/services/config/api.config';

const SubadminsTable = () => {
  const [subadmins, setSubadmins] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  

  const handleDelete = async () => {
    try {
      const res = await iparhaiServer.delete('/api/userAuth/deleteAdmin', { data: { email: selectedEmail } });

      setDeleteModal(false);
      window.location.reload();
    } catch (error) {
      console.error('Failed to delete admin:', error.response);
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    const fetchSubadmins = async () => {
      try {
        const companyId = localStorage.getItem("cid");
        const response = await authService.getsubadmins(companyId);
        setSubadmins(response.data);
      } catch (error) {
        console.error('Failed to fetch subadmins', error);
      }
    };

    fetchSubadmins();
  }, []);

  return (
    <TableContainer component={Paper}>
      <h2 style={{ textAlign: 'center' }}>Subadmins Details</h2>
      <Fade in={deleteModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            align='center'
          >
            Do you want to Delete this Admin?
          </Typography>
          <div className="flex justify-center gap-10 mt-10">
            <Button onClick={handleDelete} variant="contained" size="small">
              YES
            </Button>
            <Button onClick={() => setDeleteModal(false)} variant="outlined" size="small">
              NO
            </Button>
          </div>
        </Box>
      </Fade>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subadmins.map((subadmin) => (
            <TableRow key={subadmin.id}>
              <TableCell>{subadmin.name}</TableCell>
              <TableCell>{subadmin.email}</TableCell>
              <TableCell>
                <Button
                  onClick={() => {
                    setSelectedEmail(subadmin.email);
                    setDeleteModal(true);
                  }}
                  variant="outlined"
                  size="small"
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SubadminsTable;
