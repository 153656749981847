import React, { useState } from "react";
import {
  Box,
  Button,
  Fab,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Modal,
  TextField,
  Tabs,
  Tab,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import ScheduleIcon from "@mui/icons-material/Schedule";
import FmdBadIcon from "@mui/icons-material/FmdBad";
import SmsIcon from "@mui/icons-material/Sms";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isMobile, isTablet } from "react-device-detect";
import { useSelector } from "react-redux";
import { iparhaiServer } from "../store/services/config/api.config";

function a11yProps(index) {
  return {
    id: `form-tab-${index}`,
    "aria-controls": `form-tabpanel-${index}`,
  };
}

function FormTabPanel({ index, value, children }) {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`form-tabpanel-${index}`}
      aria-labelledby={`form-tab-${index}`}
      sx={{ padding: 2 }}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

export default function Guidelines() {
  const [move, setMove] = useState(false);
  const { duration } = useSelector((state) => state.tests);
  console.log('duration', duration)
  const [formOpen, setFormOpen] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [openDialog, setOpenDialog] = useState(isMobile || isTablet);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleScroll = () => {
    const container = document.getElementById(move ? "myContainerId" : "yourContainerId");
    container.scrollIntoView({ behavior: "smooth" });
    setMove(!move);
  };

  const navigate = useNavigate();

  const handleFormClose = () => {
    setFormOpen(false);
    setModalOpen(true);
  };

  const Submitit = async () => {
    const userId = localStorage.getItem('userId')
    const testId = localStorage.getItem('tid')
    await iparhaiServer.post("api/test/testStartTime", {
      testId,
      userId,
      testDuration: duration,
    });

    setProcessing(true); // Set processing state to true when button is clicked

    // Wait for 3 seconds before setting processing state back to false
    setTimeout(() => {
      setProcessing(false);
      navigate("/capture");
    }, 3000);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    alert("Form submitted to admin");
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const styles = {
    circle: {
      height: "55px",
      backgroundColor: "#004E8F",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "10px",
    },
    icon: {
      color: "white",
    },
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
      >
        <DialogTitle>{"Unsupported Device"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This application cannot be accessed on mobile or tablet devices. Please use a desktop or laptop to continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        id="myContainerId"
        sx={{
          backgroundColor: "#E6EDF4",
          display: "flex",
          flexDirection: "column",
          padding: 3,
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            navigate("/dashboard");
          }}
          sx={{ marginBottom: 1, width: "8em" }}
        >
          {"<"} Go Back
        </Button>

        <Box
          sx={{
            position: "fixed",
            left: {
              xs: "75%",
              sm: "85%",
              md: "90%",
              lg: "90%",
              xl: "90%",
            },
            top: {
              xs: "0",
              sm: "0",
              md: "90%",
              lg: "90%",
              xl: "90%",
            },
            zIndex: 1000,
          }}
        >
          <Fab
            sx={{ backgroundColor: "#004E8F" }}
            onClick={handleScroll}
            color="primary"
            variant="extended"
          >
            {move ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
          </Fab>
        </Box>

        <Typography sx={{ fontWeight: 800, fontSize: 15, marginBottom: 1 }}>
          Guidelines and Principles
        </Typography>

        <List sx={{ width: "100%" }}>
          <ListItem>
            <ListItemAvatar style={styles.circle}>
              <HighlightAltIcon style={styles.icon} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography sx={{ fontWeight: 800 }}>
                  Choose the correct answer
                </Typography>
              }
              secondary="Each question is multiple-choice with only one correct answer. The score against each question has also been mentioned. Select the option that best answers the question."
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar style={styles.circle}>
              <ScheduleIcon style={styles.icon} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography sx={{ fontWeight: 800 }}>Time limit</Typography>
              }
              secondary="The assessment has a specified time limit. The duration of the test along with the remaining time will be mentioned on your screen. The test will be automatically submitted once the time is over."
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar style={styles.circle}>
              <FmdBadIcon style={styles.icon} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography sx={{ fontWeight: 800 }}>
                  Avoiding Unfair Means
                </Typography>
              }
              secondary="This assessment is designed to evaluate your skills. Please refrain from using any unfair means."
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar style={styles.circle}>
              <SmsIcon style={styles.icon} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography sx={{ fontWeight: 800 }}>
                  Maintain exam integrity
                </Typography>
              }
              secondary="Do not collaborate or discuss the questions with others during the assessment."
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar style={styles.circle}>
              <SavedSearchIcon style={styles.icon} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography sx={{ fontWeight: 800 }}>
                  Review your answers
                </Typography>
              }
              secondary="Before submitting the assessment, take a moment to review your answers."
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar style={styles.circle}>
              <DoneAllIcon style={styles.icon} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography sx={{ fontWeight: 800 }}>
                  Submitting the assessment
                </Typography>
              }
              secondary="Once you have answered all the questions, click on the submit button to finalize and submit your assessment."
            />
          </ListItem>
        </List>
      </Box>
      <Box
        id="yourContainerId"
        sx={{
          marginBottom: 2,
        }}
      >
        <Typography
          sx={{
            fontWeight: 800,
            fontSize: 30,
            marginBottom: 1,
            textAlign: "center",
          }}
        >
          Remember Candidate, Your Activity Will Be Monitored Throughout the Test.
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 1.5,
            marginBottom: 1,
            textAlign: "center",
          }}
        >
          Any Unfair Means Detected You Will Be Disqualified.
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 1.5,
            marginBottom: 1,
            textAlign: "center",
          }}
        >
          This includes:
          1. Your Face Identity
          2. Your Device Activity
          3. Your Voice Detection
          4. Gadget Detection
          5. Tab Switching
        </Typography>
        <Button
          variant="contained"
          onClick={Submitit}
          color="primary"
          sx={{
            width: "10em",
            height: "3em",
            display: "block",
            marginTop: 2,
            position: "relative",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          {processing ? (
            <FontAwesomeIcon
              icon={faSpinner}
              spin
              color="#FFF"
              size="1x"
              style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          ) : (
            "Got It"
          )}
        </Button>
      </Box>
    </>
  );
}
