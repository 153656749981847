import { Backdrop, Box, Button, Fade, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Bars } from 'react-loader-spinner';
import AssigmentAccordion from '../Question accordion/AssigmentAccordion';


const AssignmentModal = ({ openAssignmentModal, setOpenAssignmentModal, questionData, assignmentLoading }) => {


    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openAssignmentModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openAssignmentModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '90%', // Adjusted for responsiveness
                        height: '90%', // Adjusted for responsiveness
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 2,
                        overflow: 'hidden', // Prevents overflow issues
                    }}
                >
                    <div className="p-6 overflow-y-scroll h-[90%]">
                        {questionData.length > 0 &&
                            questionData.map((e, id) => {
                                return (
                                    <AssigmentAccordion
                                        key={id}
                                        no={id}
                                        // questionID={e.id}
                                        question={e.question}
                                        answer={e.answer}
                                        pdfUrl={e.pdfUrl}
                                        // autoGradingMarks={e.autoGradingMarks}
                                        totalMarks={e.marks}
                                        grade={e.grade}
                                    // subject={subject}
                                    />
                                );
                            })}
                        {assignmentLoading && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '540px',
                                }}
                            >
                                <Bars
                                    height="80"
                                    width="80"
                                    color="#004e8f"
                                    ariaLabel="bars-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                            </div>
                        )}
                    </div>

                    <div className="my-4 flex gap-10 justify-center absolute bottom-2 left-1/2 transform -translate-x-1/2">
                        <Button
                            variant="contained"
                            onClick={() => setOpenAssignmentModal(false)}
                        >
                            Close
                        </Button>
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
};

export default AssignmentModal;
