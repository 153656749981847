import React, { useState, useEffect } from 'react';
import {
  Backdrop,
  Box,
  Fade,
  Modal,
  Button,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { Bars } from 'react-loader-spinner';
import QuestionAccordion from '../Question accordion/QuestionAccordion';
import { iparhaiServer } from '../../store/services/config/api.config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const ViewReportModal = ({
  open,
  onClose,
  pdfDataUrl,
  setPdfDataUrl,
  setViewReportModal,
  questionData,
  viewQuestionLoading,
  handleDownloadDocx,
  viewReport,
  test,
  userDetails,
  gradelevel,
  subject
}) => {
  const [loading, setLoading] = useState(false);
  const [enableReportsView, setEnableReportsView] = useState(false);
  const [fetchingFeedback, setFetchingFeedback] = useState(true); 
  const testId= test.test.id
  useEffect(() => {
    if (test && open) {
      fetchEnableFeedback();
    }
  }, [test, open]);

  const fetchEnableFeedback = async () => {
    setFetchingFeedback(true);
    try {
      // Fetch the current value of enableFeedback for the test
      const response = await iparhaiServer.get(`/api/question/${testId}/feedback`);
      const feedbackEnabled = response.data.enableFeedback;
      setEnableReportsView(feedbackEnabled);
    } catch (error) {
      console.error('Error fetching feedback setting:', error);
    } finally {
      setFetchingFeedback(false); // Stop the loading indicator
    }
  };

  const handleAutoGradeAll = async () => {
    setLoading(true);
    try {
      const responses = await Promise.all(
        questionData.map(({ question, answer, marks, id }) =>
          iparhaiServer.post("/api/question/gradeanswer", {
            question,
            questionID: id,
            answer,
            subject: subject,
            total_marks: marks,
            grade: gradelevel,
            is_reason: true,
            conditions: null
          })
        )
      );
      const updatedMarks = responses.map(response => response.data);
      if (updatedMarks){
         alert("All questions are graded");
         setLoading(false);
         window.location.reload(); 
      }          
    } catch (error) {
      setLoading(false);
      console.error('Error grading all questions:', error);
    }
  };

  const handleEnableReportsViewChange = async (e) => {
    const isChecked = e.target.checked;
    setEnableReportsView(isChecked);

    try {
      const response = await iparhaiServer.post('/api/question/updateFeedback', {
        testId: test.test.id, 
        enablefeedback: isChecked
      });

      if (response.data.success) {
        alert('Feedback setting updated successfully');
      } else {
        console.error('Failed to update feedback setting');
      }
    } catch (error) {
      console.error('Error updating feedback setting:', error);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70%',
            height: '80%',
            bgcolor: 'background.paper',
            boxShadow: 24,
          }}
        >
          <div className="h-full px-2 py-12">
            {pdfDataUrl ? (
              <iframe
                className="w-full h-full"
                src={pdfDataUrl}
                title="PDF Report"
              />
            ) : (
              <>
                <div className="p-6 overflow-y-scroll h-[80%]">
                  {questionData.length > 0 &&
                    questionData.map((e, id) => {
                      return (
                        <QuestionAccordion
                          key={id}
                          no={id}
                          questionID={e.id}
                          question={e.question}
                          answer={e.answer}
                          autoGradingMarks={e.autoGradingMarks}
                          totalMarks={e.marks}
                          gradelevel={gradelevel}
                          subject={subject}
                        />
                      );
                    })}
                  {viewQuestionLoading && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '540px',
                      }}
                    >
                      <Bars
                        height="80"
                        width="80"
                        color="#004e8f"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </div>
                  )}
                </div>
              </>
            )}

            <div className="my-4 flex gap-10 justify-center absolute bottom-16 left-1/2 transform -translate-x-1/2">
              <Button
                variant="contained"
                onClick={onClose}
              >
                Close
              </Button>
              {!pdfDataUrl && (
                <>
                  <Button variant="contained" onClick={handleDownloadDocx}>
                    Download
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => viewReport(userDetails.id, test, setPdfDataUrl, setViewReportModal)}
                  >
                    View Report
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleAutoGradeAll}
                  >
                    {loading && (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        spin
                      />
                    )}
                    {!loading && 'Auto Grade All'}
                  </Button>
                </>
              )}
            </div>
            {/* <div className="my-4 flex gap-10 justify-center absolute bottom-2 left-1/2 transform -translate-x-1/2">
              {!fetchingFeedback ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={enableReportsView}
                      onChange={handleEnableReportsViewChange} // Call the function on change
                    />
                  }
                  label="Do you want to enable report view for candidates?"
                />
              ) : (
                <FontAwesomeIcon icon={faSpinner} spin />
              )}
            </div> */}
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ViewReportModal;

