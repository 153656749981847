import React, { useState, useRef } from 'react';
import { Button, Modal, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, MenuItem, TablePagination } from '@mui/material';
import { UploadFile } from '@mui/icons-material';
import Sidebar from '../Navigation/SideBar';
import * as XLSX from 'xlsx';
import { downloadFile } from "../../utils/micsfun";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import authService from '../../store/services/auth.service';
import {Fade} from '@mui/material';
import { iparhaiServer } from '../../store/services/config/api.config';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function AddStudents() {
  const [open, setOpen] = useState(false);
  const [students, setStudents] = useState([]);
  const [subadmins, setSubadmins] = useState([]);
  
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [studentPasswords, setStudentPasswords] = useState([]);
  const [usernames, setUsernames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [manualUsers, setManualUsers] = useState([]);
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setError('No file selected');
      return;
    }
  
    const reader = new FileReader();
    reader.onload = (evt) => {
      const data = evt.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
  
      // Check for duplicate ARNs or Roll Nos
      const arnSet = new Set();
      let duplicateFound = false;
  
      jsonData.forEach((student) => {
        const arn = student.ARN || student['ARN/Roll No'];
        if (arnSet.has(arn)) {
          duplicateFound = true;
          setError(`Please Check  Two Same ARN/Roll No found: ${arn}`);
        } else {
          arnSet.add(arn);
        }
      });
  
      if (duplicateFound) {
        return; // Stop further processing if a duplicate is found
      }
  
      // No duplicates, proceed
      setStudents(jsonData);
      generatePasswords(jsonData.length);
      generateUsernames(jsonData);
      setFileName(file.name);
      setError('');
    };
    reader.readAsBinaryString(file);
  };
  

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const generatePasswords = (count) => {
    const passwords = [];
    for (let i = 0; i < count; i++) {
      passwords.push(generateRandomPassword());
    }
    setStudentPasswords(passwords);
  };

  const generateUsernames = (students) => {
    const code = localStorage.getItem("co") || '';
    const generatedUsernames = students.map(student => {
      return `${student.ARN || student['ARN/Roll No']}-${code}`;
    });
    setUsernames(generatedUsernames);
  };

  const companyId = localStorage.getItem("cid");

  const generateRandomPassword = () => {
    const chars = 'abcdefghijkmnopqrstuvwxyzABCDEFGHIJKLMNPQRSTUVWXYZ123456789@';
    let password = '';
    for (let i = 0; i < 8; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return password;
  };
  const handleDelete = async () => {
    try {
      const res = await iparhaiServer.delete('/api/userAuth/deleteAdmin', { data: { username: selectedEmail } });

      setDeleteModal(false);
      window.location.reload();
    } catch (error) {
      console.error('Failed to delete admin:', error.response);
      setDeleteModal(false);
    }
  };

  const handleAddStudents = async () => {
    setLoading(true);
    const studentsWithDetails = students.map((student, index) => ({
      ...student,
      password: studentPasswords[index],
      username: usernames[index]
    }));

    const requestBody = {
      companyId,
      students: studentsWithDetails
    };
    try {
      const response = await authService.registerBulkStudents(requestBody);

      if (response) {
        downloadPDF(studentsWithDetails);
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Failed to register students');
    } finally {
      setLoading(false);
    }
  };

  const downloadPDF = (students) => {
    const doc = new jsPDF();
    const tableColumn = Object.keys(students[0]);
    const tableRows = [];

    students.forEach(student => {
      const studentData = tableColumn.map(key => student[key]);
      tableRows.push(studentData);
    });

    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    doc.save('students.pdf');
  };

  const fetchManualUsers = async () => {
    try {
      const response = await authService.getmanualusers(companyId);
  
      // Log the response to check its structure
      console.log("API Response:", response);
  
      // Access the data property of the response
      if (response && Array.isArray(response.data)) {
        setManualUsers(response.data);
      } else {
        console.error("Unexpected response format:", response);
        setManualUsers([]); // Set to an empty array if response is not as expected
      }
    } catch (error) {
      console.error("Error fetching manual users:", error);
      setManualUsers([]); // Set to an empty array in case of error
    }
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div style={{ display: 'flex' }}>
      <Sidebar />
      <div style={{ flex: 1 }}>
        <div style={{ marginTop: 50, padding: '0 20px' }}>
          <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<UploadFile />}>
            Add Candidate
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-title" variant="h6" component="h2">
                Upload Excel File
              </Typography>
              <MenuItem onClick={() => downloadFile("bulktemplate.xlsx")}>Download Template</MenuItem>

              <Button variant="contained" component="label" onClick={handleButtonClick} style={{ marginTop: 20 }}>
                {fileName ? 'Change File' : 'Upload File'}
              </Button>
              <input
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
                ref={fileInputRef}
                style={{ display: 'none' }}
              />

              {error && <Typography color="error" variant="body2" style={{ marginTop: 10 }}>{error}</Typography>}

              <Button
                variant="contained"
                onClick={handleClose}
                color="secondary"
                style={{ marginTop: 20, marginLeft: 20 }}
              >
                Close
              </Button>
            </Box>
          </Modal>

          <TableContainer component={Paper} style={{ marginTop: 20 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {students.length > 0 && Object.keys(students[0]).map((key) => (
                    <TableCell key={key}>{key}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {students.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((student, index) => (
                  <TableRow key={index}>
                    {Object.values(student).map((value, i) => (
                      <TableCell key={i}>{value}</TableCell>
                    ))}
                    <TableCell>{usernames[page * rowsPerPage + index]}</TableCell>
                    <TableCell>{studentPasswords[page * rowsPerPage + index]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
           
            component="div"
            count={students.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <Typography variant="h6" style={{ marginTop: 20 }}>
            Total Number of Students: {students.length}
          </Typography>
          <Fade in={deleteModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            align='center'
          >
            Do you want to Delete this Admin?
          </Typography>
          <div className="flex justify-center gap-10 mt-10">
            <Button onClick={handleDelete} variant="contained" size="small">
              YES
            </Button>
            <Button onClick={() => setDeleteModal(false)} variant="outlined" size="small">
              NO
            </Button>
          </div>
        </Box>
      </Fade>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddStudents}
            style={{ marginTop: 20 }}
            disabled={students.length === 0 || loading}
          >
            {loading ? (
              <>
                Registering Students
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  style={{ marginLeft: "8px" }}
                />
              </>
            ) : (
              "Register Students"
            )}
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={fetchManualUsers}
            style={{ marginTop: 20, marginLeft:20 }}
          >
            Show Candidates
          </Button>

          <TableContainer component={Paper} style={{ marginTop: 20 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(manualUsers) && manualUsers.length > 0 ? (
                  manualUsers.map((user, index) => (
                    <TableRow key={index}>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.username}</TableCell>
                      <TableCell>
                <Button
                  onClick={() => {
                     setSelectedEmail(user.username);
                    setDeleteModal(true);
                  }}
                  variant="outlined"
                  size="small"
                >
                  Delete
                </Button>
              </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={2}>No data available</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}

export default AddStudents;
