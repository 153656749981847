import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import TestSideBar from "../components/Test/TestSideBar";
import Testt from "../store/actions/test";
import QuestionCard from "../components/Test/QuestionCard";
import testService from "../store/services/test.service";
import { decrypt } from "../components/encryptfunction/decrypt";
import { Circles } from "react-loader-spinner";
import ChatSupport from "../components/Chat Support/ChatSupport";
import moment from "moment";
import { iparhaiServer } from "../store/services/config/api.config";

const useSpeechToText = () => {
  useEffect(() => {
    const recognition = new (window.SpeechRecognition ||
      window.webkitSpeechRecognition)();
    recognition.continuous = true;
    recognition.onresult = (event) => {
      const lastResult = event.results[event.results.length - 1];
      const transcriptText = lastResult[0].transcript;
    };
    recognition.start();
    return () => {
      recognition.stop();
    };
  }, []);
};

function Test() {
  const userId = localStorage.getItem("userId");
  const testId = localStorage.getItem("tid");
  const [copypaste, setCopypaste] = useState(0);
  const [loading, setLoading] = useState(true); // Start with loading true
  const [allTest, setAllTest] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [error, setError] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  const dispatch = useDispatch();
  const questionsObj = useSelector((state) => state.tests.questions);

  useSpeechToText();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await testService.getTests(userId);
        const { iv, encryptedData } = res.tests;
        const decryptedText = decrypt(encryptedData, iv);
        const parsedData = JSON.parse(decryptedText);
        setAllTest(parsedData);

        const filterTest = parsedData.find((e) => e.id === testId);
        if (filterTest) {
          setCopypaste(filterTest.enableCopyPaste);
          const { startTime, expireTime } = await getStartTime();
          const res = await iparhaiServer.get(`api/test/getCurrentTime/a`);
          let currentTime = res.data;
          if (startTime) {
            setStartTime(startTime);

            // let expireDate = moment(filterTest.expiredate);
            let diffFromStartToExpire = expireTime.diff(startTime) / 1000;
            let remainingTime = filterTest.remainingTime;
            let newExpireTime;
            if (diffFromStartToExpire > remainingTime) {
              newExpireTime = startTime.add(remainingTime, "seconds");
              // let currentTime = moment();
              remainingTime = expireTime.diff(currentTime) / 1000;
            } else {
              newExpireTime = expireTime;
              // let currentTime = moment();
              remainingTime = newExpireTime.diff(currentTime) / 1000;
            }

            if (remainingTime < 1) {
              remainingTime = 0;
            }

            dispatch(Testt.updateDuration(Math.floor(remainingTime)));
            dispatch(Testt.updateMode(filterTest.QuestionType));
            dispatch(Testt.updateaiproctor(filterTest.enableAiVigilance));
            dispatch(
              Testt.updateenableNegativeMarking(
                filterTest.enableNegativeMarking
              )
            );

            setDataLoaded(true); // Data has been processed
          }
        }
      } catch (err) {
        console.error(err.response);
        setError("Failed to fetch test data");
      } finally {
        setLoading(false); // Stop loading regardless of success or failure
      }
    };

    fetchData();
  }, [userId, testId, dispatch]);

  const getStartTime = async () => {
    try {
      const res = await iparhaiServer.get(
        `/api/test/getStartTimeAndExpireTime/${userId}&${testId}`
      );
      let startTime = moment(res.data.startTime);
      let expireTime = moment(res.data.expireTime);
      return { startTime, expireTime };
    } catch (error) {
      console.error(error);
      setError("Failed to fetch start time");
      return null;
    }
  };

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     const confirmationMessage = "Are you sure you want to leave this page?";
  //     event.preventDefault();
  //     event.returnValue = confirmationMessage;
  //     return confirmationMessage;
  //   };

  //   const handlePopState = (event) => {
  //     const confirmationMessage =
  //       "Are you sure you want to leave this page as it will end your test?";
  //     const confirmLeave = window.confirm(confirmationMessage);
  //     if (!confirmLeave) {
  //       window.history.pushState(null, "", window.location.href);
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   window.addEventListener("popstate", handlePopState);

  //   window.history.pushState(null, "", window.location.href);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //     window.removeEventListener("popstate", handlePopState);
  //   };
  // }, []);

  const questions =
    Object.values(questionsObj).length === 0 && allTest.length > 0
      ? allTest.find((e) => e.id === testId)?.questions || []
      : Object.values(questionsObj);

  if (loading) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: 4,
        }}
      >
        <Circles
          height="80"
          width="80"
          color="#004e8f"
          ariaLabel="circles-loading"
          visible={true}
        />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: 4,
        }}
      >
        <p>{error}</p>
      </Box>
    );
  }

  if (!dataLoaded) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: 4,
        }}
      >
        <p>Loading test details...</p>
      </Box>
    );
  }

  return (
    <Box>
      <TestSideBar>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: 4,
          }}
        >
          {questions.length > 0 ? (
            <QuestionCard questions={questions} copyPaste={copypaste} />
          ) : (
            <p>No questions available</p>
          )}
        </Box>
        <ChatSupport key={1} />
      </TestSideBar>
    </Box>
  );
}

export default Test;
