import { HashRouter, Routes, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import AdminRoute from "./AdminRoute";
import Home from "../../pages/Home";
import Account from "../../pages/Account";
import AboutUs from "../../pages/AboutUs";
import ContactUs from "../../pages/ContactUsPage/ContactUs";
import Assessments from "../../pages/dashboardPages/Assessments";
import Organization from "../../pages/dashboardPages/Organization";
import Scheduled from "../../pages/dashboardPages/Scheduled";
import Testresult from "../../pages/dashboardPages/Testresult";
import Password from "../../pages/dashboardPages/Password";
import Test from "../../pages/Test";
import TestNavbar from "../Test/TestNavbar";
import WebcamCapture from "../Test/WebcamCapture";
import CustomizeTest from "../../pages/adminPages/CustomizeTest";
import User from "../../pages/userPages/User";
import Result from "../../pages/userPages/Result";
import UserRoute from "./UserRoute";
import jwtDecode from "jwt-decode";
import Signup from "../Account.js/Signup";
import Adminsignup from "../Account.js/Adminsignup";
import Instruction from "../Test/Instruction";
import Login from "../Account.js/Login";
import OTPScreen from "../../pages/otp";
import ForgetPassword from "../../pages/forgetpassword";
import ResetPassword from "../../pages/resetpassword";
import Guidelines from "../../pages/Guidelines";
import AdminDetails from "../admin details/AdminDetails";
import Subadmins from "../admin details/Subadmins";
import subadminform from "../Account.js/subadminform";
import Subadminform from "../Account.js/subadminform";
import ChatSupport from "../Chat Support/ChatSupport";
import AdminSupport from "../Chat Support/AdminSupport";
import Addstudents from "../admin details/addstudents";
import TwoFactorAuthentication from "../settings/TwoFactorAuthentication";
import Showresult from "../Showresult/showresult";



function Navigation() {

  let loggedIn = false;
  let user = "";

  if (localStorage.token) {
    loggedIn = true;
    user = jwtDecode(localStorage.token);
  }
  return (
    <HashRouter basename="/">
      <Routes>
        <Route
          exact
          path="/"
          element={
            <PublicRoute isAuthenticated={loggedIn}>
              <Home />
            </PublicRoute>
          }
        />
        <Route
          path="/about-us"
          element={
            <PublicRoute isAuthenticated={loggedIn}>
              <AboutUs />
            </PublicRoute>
          }
        />
        {/* <Route
          path="/account"
          element={
            <PublicRoute>
              <Account />
            </PublicRoute>
          }
        /> */}
        <Route
          path="/role-error"
          element={
            <PublicRoute isAuthenticated={loggedIn}>
              {/* <RoleError /> */}
            </PublicRoute>
          }
        />
        <Route path="/otp" element={<OTPScreen />} />
        {/* <Route path="/forgetpassword" element={<ForgetPassword />} /> */}
        <Route path="/resetpassword/:resetToken" element={<ResetPassword />} />

        <Route
          path="/contact-us"
          element={
            <PublicRoute isAuthenticated={loggedIn}>
              <ContactUs />
            </PublicRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute isAuthenticated={loggedIn}>
              {/* <Login /> */}
              <Account type={"login"} />
            </PublicRoute>
          }
        />
        <Route
          path="/Signup"
          element={
            <PublicRoute isAuthenticated={loggedIn}>
              {/* <Signup /> */}
              <Account type={"signup"} />
            </PublicRoute>
          }
        />
        <Route
          path="/adminsignup"
          element={
            <PublicRoute isAuthenticated={loggedIn}>
              <Adminsignup />
            </PublicRoute>
          }
        />
        <Route
          path="/subadmin"
          element={
            <PublicRoute isAuthenticated={loggedIn}>
              <Subadminform/>
            </PublicRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute isAuthenticated={loggedIn} user={user}>
              <Assessments />
            </PrivateRoute>
          }
        />
          <Route
          exact
          path="/subadmins"
          element={
            <PrivateRoute isAuthenticated={loggedIn} user={user}>
              <Subadmins />
            </PrivateRoute>
          }
        />
        <Route
          path="/adminDetails"
          element={
            <PrivateRoute isAuthenticated={loggedIn} user={user}>
              <AdminDetails />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/guidelines"
          element={
            <>
              <PrivateRoute isAuthenticated={loggedIn} user={user}>
                <Guidelines />
              </PrivateRoute>
            </>
          }
        />
        <Route
          exact
          path="/organization"
          element={
            <PrivateRoute isAuthenticated={loggedIn} user={user}>
              <Organization />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/scheduled"
          element={
            <PrivateRoute isAuthenticated={loggedIn} user={user}>
              <Scheduled />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/support"
          element={
            <PrivateRoute isAuthenticated={loggedIn} user={user}>
              <AdminSupport />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/2FA"
          element={
            <PrivateRoute isAuthenticated={loggedIn} user={user}>
              <TwoFactorAuthentication />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/testresult"
          element={
            <PrivateRoute isAuthenticated={loggedIn} user={user}>
              <Testresult />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/password"
          element={
            <PrivateRoute isAuthenticated={loggedIn} user={user}>
              <Password />
            </PrivateRoute>
          }
        />
          <Route
          exact
          path="/testfeedback"
          element={
            <PrivateRoute isAuthenticated={loggedIn} user={user}>
              <Showresult />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/test"
          element={
            <>
              <TestNavbar />
              <Test />
            </>
          }
        />
        <Route
          exact
          path="/capture"
          element={
            <>
              <TestNavbar />
              <WebcamCapture />
            </>
          }
        />
        <Route
          exact
          path="/instructions"
          element={
            <>
              <TestNavbar />
              <Instruction />
            </>
          }
        />

        <Route
          exact
          path="/editassessment"
          element={
            // <AdminRoute isAuthenticated={loggedIn} user={user}>
            //   <CustomizeTest />
            // </AdminRoute>
            <PrivateRoute isAuthenticated={loggedIn} user={user}>
              <CustomizeTest />
            </PrivateRoute>
          }
        />
           <Route
          exact
          path="/addcandidates"
          element={
            // <AdminRoute isAuthenticated={loggedIn} user={user}>
            //   <CustomizeTest />
            // </AdminRoute>
            <PrivateRoute isAuthenticated={loggedIn} user={user}>
              <Addstudents />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/user"
          element={
            <UserRoute isAuthenticated={loggedIn} user={user}>
              <User />
            </UserRoute>
          }
        />
        <Route
          exact
          path="/result"
          element={
            <PrivateRoute isAuthenticated={loggedIn} user={user}>
              <Result />
            </PrivateRoute>
          }
        />
      </Routes>
    </HashRouter>
  );
}

export default Navigation;
