import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@mui/material'
import React from 'react'
import { ArrowDropDownIcon } from "@mui/x-date-pickers";

const AssigmentAccordion = ({ no, questionID, question, answer, pdfUrl, totalMarks, grade, subject, autoGradingMarks }) => {
    return (
        <>
            <Accordion key={no}>
                <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <div className="w-full flex justify-between">
                        <Typography>
                            <span className="font-semibold">QUESTION No. {++no}:</span>{" "}
                            {question}
                        </Typography>
                        <p className="mx-4 bg-[#b0c8dc] p-1 rounded-lg "><span className="font-medium">Total Marks:</span> <span className="text-[#004e8f] font-semibold">{totalMarks}</span></p>
                    </div>
                </AccordionSummary>
                <AccordionDetails className="">

                    <Typography sx={{
                        overflowWrap: "break-word",
                        wordWrap: "break-word",
                        hyphens: "auto",
                    }} className="max-w-[95%]">
                        <span className="font-semibold">Assignment: </span>

                    </Typography>
                    <div className="flex justify-end"><Button variant='contained' color={grade?'success':'primary'}>Auto Grading</Button></div>
                    <div className="h-[700px] flex justify-center ">
                        <iframe
                            src={pdfUrl}
                            width="70%"
                            height="100%"
                            title="PDF Preview"
                            style={{ border: 'none' }} // Removes border
                        ></iframe>
                    </div>
                       

                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default AssigmentAccordion
