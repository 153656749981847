import React, { useEffect, useState } from "react";
import { 
  Box, 
  Grid, 
  Pagination, 
  Typography, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  Divider 
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import SideBar from "../../components/Navigation/SideBar";
import { iparhaiServer } from "../../store/services/config/api.config";

function Showresult() {
  const [page, setPage] = useState(1);
  const [testScores, setTestScores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const itemsPerPage = 5;
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const fetchTestResults = async () => {
      try {
        const response = await iparhaiServer.post('/api/question/gettestresults', { userId });
        setTestScores(response.data);
        setLoading(false);
      } catch (err) {
        setError('No feedback or Results Updated from your Assignee');
        setLoading(false);
      }
    };

    fetchTestResults();
  }, []);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentTests = testScores.slice(startIndex, endIndex);

  return (
    <Box
      height="100%"
      sx={{
        position: "relative",
        bgcolor: "#F8F8F8",
        p: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={0} sm={3}>
          <SideBar />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Box sx={{ ml: { xs: "2rem", sm: "6.5rem", md: "1rem" }, mr: "1rem" }}>
            <Typography mt={2} fontWeight={800} variant="h4">
              Your Assessments Result
            </Typography>
            <Typography mb={3} fontSize={16} color="text.secondary">
              Feedback and results from your assignments
            </Typography>
            
            {loading ? (
              <Typography align="center">Loading...</Typography>
            ) : error ? (
              <Typography align="center" color="error">{error}</Typography>
            ) : (
              <>
                {testScores.length > itemsPerPage && (
                  <Pagination
                    count={Math.ceil(testScores.length / itemsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                    sx={{ display: "flex", justifyContent: "center", mb: 3 }}
                  />
                )}
                {currentTests.length > 0 ? (
                  currentTests.map((test) => (
                    <Accordion key={test.testId}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{
                          bgcolor: 'primary.main', // Background color for test name/description
                          color: 'white', // Text color
                        }}
                      >
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography variant="h6">{test.name}</Typography>
                          <Typography variant="body2" sx={{ mt: 0.5 }}>
                            {test.type}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        {/* Check if test is Text-based or MCQ-based */}
                        {test.type === 'Text-based' ? (
                          test.questions.map((question, index) => (
                            <Accordion key={question.id}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                sx={{ bgcolor: 'secondary.main', color: 'black' }} // Secondary color for question header
                              >
                                <Typography variant="h6">
                                  Question {index + 1}: {question.question}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography variant="body1" color="text.secondary" sx={{ mb: 1 }}>
                                  Answer: {question.answer}
                                </Typography>
                               
                                <Typography variant="body2" color="text.primary">
                                  Marks: {question.marks}
                                </Typography>
                                <Typography variant="body2" color="text.primary">
                            
                                  Obtained Marks: {question.autoGradingMarks  || "Marks / Feedback not given by Assigne"}
                                </Typography>
                                <Divider sx={{ mt: 2, mb: 2 }} />
                              </AccordionDetails>
                            </Accordion>
                          ))
                        ) : (
                          // Show score for MCQ-based tests
                          <Typography variant="h6" color="text.primary">
                            Score: {test.score !== null ? test.score : 'Not available'}
                          </Typography>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  ))
                ) : (
                  <Typography variant="body1" color="text.secondary" align="center">
                    No results available
                  </Typography>
                )}
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Showresult;
