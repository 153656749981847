import { Avatar, Box, Fab, Paper, Typography } from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DescriptionIcon from "@mui/icons-material/Description";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SecurityIcon from "@mui/icons-material/Security";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import KeyIcon from "@mui/icons-material/Key";
import MenuIcon from "@mui/icons-material/Menu";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link, useNavigate } from "react-router-dom";
import {
  clearMessages,
  setCompanyId,
  setShowAlert,
} from "../../store/actions/newMessage";

import user from "../../store/actions/user";
import authService from "../../store/services/auth.service";
import jwtDecode from "jwt-decode";
// import newMessage from "../../store/actions/newMessage";

function Sidebar() {
  // const messages = useSelector((state)=>state.messageReducer.adminMessages)
  const showMessageAlert = useSelector(
    (state) => state.messageReducer.showAlert
  );
  const [showSidebar, setShowSidebar] = useState(false); // State to manage sidebar visibility
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const token = localStorage.getItem("token");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const curloc = location.pathname;

  const userData = useSelector((state) => state.user);

  let first;
  let second;
  let name;
  let email;

  if (userData.name && userData.email) {
    first = userData.name[0];
    if (userData.name.split(" ").length > 1)
      second = userData.name.split(" ")[1][0];

    name = userData.name;
    email = userData.email;
  }

  useEffect(() => {
    authService
      .getUser(token)
      .then((res) => {
        localStorage.setItem("name", res.data.user.name);
        dispatch(user.setUser(res.data.user));
        localStorage.setItem("userId", res.data.user.id);
      })

      .catch((err) => {
        // dispatch(auth.authenticated(false));
      });
  }, [token, dispatch]);

  // first = "W";
  // second = "I";
  // name = "Wajdan Ismail";
  // email = "wajdan.ismail@syslab.ai";

  async function handleLogout() {
    try {
      if (window.location.hostname === "localhost") {
        window.location.href = "http://localhost:3000";
      } else {
        window.location.href = "https://proctorparhai.com";
      }

      dispatch(setCompanyId(null));
      dispatch(user.setUser({}));

      localStorage.clear();
    } catch (error) {
      console.error("Logout failed:", error);
    }
  }

  return (
    <>
      <Box
        display={{ xs: "block", sm: "none" }}
        position="fixed"
        top={0}
        left={0}
        zIndex={999}
      >
        <Box sx={{ "& > :not(style)": { m: 1 } }}>
          <Fab
            sx={{
              width: "fit-content",
              height: "fit-content",
              padding: "5px",
            }}
            onClick={toggleSidebar}
            color="primary"
            aria-label="add"
          >
            <MenuIcon
              sx={
                {
                  // fontSize: "12vw",
                }
              }
            />
          </Fab>
        </Box>
      </Box>
      <Box
        // mr={1}
        width={{
          xs: showSidebar ? "70vw" : "0vw", //  between 0px and 600px
          sm:
            jwtDecode(localStorage.token).role === 2 || 4
              ? curloc === "/dashboard"
                ? "42.5vw"
                : "36vw"
              : "35vw", //  between 600px and 960px
          md:
            jwtDecode(localStorage.token).role === 2 || 4
              ? curloc === "/dashboard"
                ? "27vw"
                : "26vw"
              : "27vw", //  between 960px and 1280px
          lg:
            jwtDecode(localStorage.token).role === 2 || 4
              ? curloc === "/dashboard"
                ? "27vw"
                : "26vw"
              : "27vw", // between 1280px and 1920px
          xl:
            jwtDecode(localStorage.token).role === 2 || 4
              ? curloc === "/dashboard"
                ? "27.5vw"
                : "25vw"
              : "27vw", //  above 1920px
        }}
        height="100%"
        bgcolor="#004E8F"
        color={"white"}
        sx={{
          top: 0,
          left: 0,
          transition: "width 0.3s ease-in-out",
          zIndex: 998,
          position: {
            xs: "fixed",
            sm: "static",
          },
          boxSizing: "border-box",
          display: {
            xs: showSidebar ? "block" : "none", //  between 0px and 600px
            sm: "flex", //  between 600px and 960px
            md: "flex", //  between 960px and 1280px
            lg: "flex", // between 1280px and 1920px
            xl: "flex", //  above 1920px
          },
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
        }}
      >
        <Box
          width="100%"
          height="100vh"
          p={2}
          bgcolor="#004E8F"
          sx={{ boxSizing: "border-box" }}
        >
          <Box
            sx={{
              position: "relative",
              left: "50%",
              borderRadius: "50%",
              transform: "translate(-50%, +50%)",
              height: "75px",
              width: "75px",
              bgcolor: "#004E8F",
            }}
          >
            <Avatar
              sx={{
                position: "relative",
                transform: "translate(2.5px, 2.5px)",
                height: "70px",
                width: "70px",
                bgcolor: "#D9E4EE",
                color: "#004E8F",
                fontSize: "38px",
              }}
            >
              {first}
              {second}
            </Avatar>
          </Box>

          <Box
            width="100%"
            textAlign="center"
            bgcolor="white"
            color={"#004E8F"}
            borderRadius={2}
            mb={5}
            pb={2}
            pt={5}
          >
            <Typography fontWeight={800}>{name}</Typography>
            <Typography fontWeight={100}>{email}</Typography>
          </Box>
          <List>
            {jwtDecode(localStorage.token).role === 2 ? (
              // 1 === 1
              <>
                <ListItem disablePadding>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "white",
                      backgroundColor: curloc === "/subadmins" ? "#D9E4EE" : "",
                      borderRadius: "5px",
                    }}
                    to="/subadmins"
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <SupervisorAccountIcon
                          sx={{
                            color:
                              curloc === "/subadmins" ? "#004e8f" : "white",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          color: curloc === "/subadmins" ? "#004e8f" : "white",
                        }}
                        primary="Sub Admins"
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>
                <ListItem disablePadding>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "white",
                      backgroundColor:
                        curloc === "/addcandidates" ? "#D9E4EE" : "",
                      borderRadius: "5px",
                    }}
                    to="/addcandidates"
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <AdminPanelSettingsIcon
                          sx={{
                            color:
                              curloc === "/addcandidates" ? "#004e8f" : "white",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          color:
                            curloc === "/addcandidates" ? "#004e8f" : "white",
                        }}
                        primary="Add Candidates"
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>
                <ListItem disablePadding>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "white",
                      backgroundColor: curloc === "/dashboard" ? "#D9E4EE" : "",
                      borderRadius: "5px",
                    }}
                    to="/"
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <DescriptionIcon
                          sx={{
                            color:
                              curloc === "/dashboard" ? "#004e8f" : "white",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          color: curloc === "/dashboard" ? "#004e8f" : "white",
                        }}
                        primary="Assessments"
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>
                <ListItem disablePadding>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "white",
                      backgroundColor: curloc === "/scheduled" ? "#D9E4EE" : "",
                      borderRadius: "5px",
                    }}
                    to="/scheduled"
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <AssessmentIcon
                          sx={{
                            color:
                              curloc === "/scheduled" ? "#004e8f" : "white",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          color: curloc === "/scheduled" ? "#004e8f" : "white",
                        }}
                        primary="Assessment Result"
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>
                <ListItem disablePadding>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "white",
                      backgroundColor: curloc === "/support" ? "#D9E4EE" : "",
                      borderRadius: "5px",
                    }}
                    to="/support"
                    onClick={() => dispatch(setShowAlert(false))}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <SupportAgentRoundedIcon
                          sx={{
                            color: curloc === "/support" ? "#004e8f" : "white",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          color: curloc === "/support" ? "#004e8f" : "white",
                        }}
                        primary="Support"
                      />
                      {showMessageAlert && (
                        <Paper
                          sx={{
                            bgcolor: curloc === "/support" ? "#D9E4EE" : "",
                            color: curloc === "/support" ? "white" : "#004e8f",
                          }}
                          className="text-xs px-2 animate-bounce py-1 ml-4"
                        >
                          New Message
                        </Paper>
                      )}
                    </ListItemButton>
                  </Link>
                </ListItem>
              </>
            ) : jwtDecode(localStorage.token).role === 4 ? (
              // 1 === 1
              <>
                {/* <ListItem disablePadding>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "white",
                      backgroundColor: curloc === "/subadmins" ? "#D9E4EE" : "",
                      borderRadius: "5px",
                    }}
                    to="/subadmins"
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <SupervisorAccountIcon
                          sx={{
                            color:
                              curloc === "/subadmins" ? "#004e8f" : "white",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          color: curloc === "/subadmins" ? "#004e8f" : "white",
                        }}
                        primary="Sub Admins"
                      />
                    </ListItemButton>
                  </Link>
                </ListItem> */}
                <ListItem disablePadding>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "white",
                      backgroundColor: curloc === "/dashboard" ? "#D9E4EE" : "",
                      borderRadius: "5px",
                    }}
                    to="/"
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <DescriptionIcon
                          sx={{
                            color:
                              curloc === "/dashboard" ? "#004e8f" : "white",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          color: curloc === "/dashboard" ? "#004e8f" : "white",
                        }}
                        primary="Assessments"
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>
                <ListItem disablePadding>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "white",
                      backgroundColor: curloc === "/scheduled" ? "#D9E4EE" : "",
                      borderRadius: "5px",
                    }}
                    to="/scheduled"
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <AssessmentIcon
                          sx={{
                            color:
                              curloc === "/scheduled" ? "#004e8f" : "white",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          color: curloc === "/scheduled" ? "#004e8f" : "white",
                        }}
                        primary="Assessment Result"
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>
              </>
            ) : jwtDecode(localStorage.token).role === 3 ? (
              <>
                <ListItem disablePadding>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "white",
                      backgroundColor: curloc === "/dashboard" ? "#D9E4EE" : "",
                      borderRadius: "5px",
                    }}
                    to="/"
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <AdminPanelSettingsIcon
                          sx={{
                            color:
                              curloc === "/dashboard" ? "#004e8f" : "white",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          color: curloc === "/dashboard" ? "#004e8f" : "white",
                        }}
                        primary="Create Admin"
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>
                <ListItem disablePadding>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "white",
                      backgroundColor:
                        curloc === "/adminDetails" ? "#D9E4EE" : "",
                      borderRadius: "5px",
                    }}
                    to="/adminDetails"
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <AdminPanelSettingsIcon
                          sx={{
                            color:
                              curloc === "/adminDetails" ? "#004e8f" : "white",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          color:
                            curloc === "/adminDetails" ? "#004e8f" : "white",
                        }}
                        primary="Admin Details"
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>
              </>
            ) :  (
              <>
                <ListItem disablePadding>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "white",
                      backgroundColor: curloc === "/dashboard" ? "#D9E4EE" : "",
                      borderRadius: "5px",
                    }}
                    to="/"
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <CalendarTodayIcon
                          sx={{
                            color:
                              curloc === "/dashboard" ? "#004e8f" : "white",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          color: curloc === "/dashboard" ? "#004e8f" : "white",
                        }}
                        primary="Scheduled Assessments"
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>
                <ListItem disablePadding>
              <Link
                style={{
                  textDecoration: "none",
                  color: "white",
                  backgroundColor: curloc === "/testfeedback" ? "#D9E4EE" : "",
                  borderRadius: "5px",
                }}
                to="/testfeedback"
              >
                <ListItemButton>
                  <ListItemIcon>
                    <KeyIcon
                      sx={{
                        color: curloc === "/testfeedback" ? "#004e8f" : "white",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      color: curloc === "/testfeedback" ? "#004e8f" : "white",
                    }}
                    primary="Result Feedbacks"
                  />
                </ListItemButton>
              </Link>
            </ListItem>
              </>
              
            )}
            <ListItem disablePadding>
              <Link
                style={{
                  textDecoration: "none",
                  color: "white",
                  backgroundColor: curloc === "/2FA" ? "#D9E4EE" : "",
                  borderRadius: "5px",
                }}
                to="/2FA"
                // onClick={() => dispatch(setShowAlert(false))}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <SecurityIcon
                      sx={{
                        color: curloc === "/2FA" ? "#004e8f" : "white",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      color: curloc === "/2FA" ? "#004e8f" : "white",
                    }}
                    primary="Two Factor Authentication"
                  />
                </ListItemButton>
              </Link>
            </ListItem>
            <ListItem disablePadding>
              <Link
                style={{
                  textDecoration: "none",
                  color: "white",
                  backgroundColor: curloc === "/password" ? "#D9E4EE" : "",
                  borderRadius: "5px",
                }}
                to="/password"
              >
                <ListItemButton>
                  <ListItemIcon>
                    <KeyIcon
                      sx={{
                        color: curloc === "/password" ? "#004e8f" : "white",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      color: curloc === "/password" ? "#004e8f" : "white",
                    }}
                    primary="Change Password"
                  />
                </ListItemButton>
              </Link>
            </ListItem>


            
            
          
            <ListItem disablePadding>
              <ListItemButton onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutOutlinedIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </ListItem>
            
          </List>
        </Box>
      </Box>
    </>
  );
}

export default Sidebar;
